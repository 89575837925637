<template>
  <loading v-if="isLoading" />
  <main v-else class="courses-container">
    <!-- <card
      v-for="(course, index) in coursesData"
      :key="index"
      :coursesData="course"
    /> -->
  </main>
</template>

<script>
import Loading from "@/components/loading/loading.vue";
// import Card from "@/components/classrooms/enrolledCourse/card/card.vue";

export default {
  components: {
    Loading,
    Card,
  },
  data() {
    return {
      isLoading: false,
      coursesData: null,
    };
  },
  created() {
    this.getCoursesData();
  },
  methods: {
    async getCoursesData() {
      try {
        this.isLoading = true;
        const res = await this.$http.get("/guardian/courses");
        this.coursesData = res.data;
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
